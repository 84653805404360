export const pspConfig = () => {
    return {
        realeasypay: {
            name: "Credit/Debit Card / REP",
            currencies: ["USD"],
        },
        cascading: {
            name: "Credit/Debit Card / CASC",
            currencies: ["USD", "RUB"],
        },
        finanic: {
            name: "Credit/Debit Card / FIN",
            currencies: ["USD", "EUR"],
        },
        betatransfer: {
            name: "Credit/Debit Card / BT",
            currencies: ["RUB", "USD"],
        },
        prmoney: {
            name: "Credit/Debit Card / PRM",
            currencies: ["USD"],
        },
        prmoney_eur: {
            name: "Credit/Debit Card / PRM SEPA",
            currencies: ["EUR"],
        },
        prmoney_ru: {
            name: "Credit/Debit Card / PRM RU",
            currencies: ["RUB"],
        },
        starbill_usd: {
            name: "Credit/Debit Card / SBU",
            currencies: ["USD"],
        },
        starbill_eur: {
            name: "Credit/Debit Card / SBE",
            currencies: ["EUR"],
        },
        paypound: {
            name: "Credit/Debit Card / PP",
            currencies: ["USD", "EUR", "GBP"],
        },
        kryptova: {
            name: "Credit/Debit Card / KR",
            currencies: ["USD", "EUR", "GBP"],
        },
        walletix: {
            name: "Credit/Debit Card / WI",
            currencies: ["USD", "EUR", "RUB", "PLN"],
        },
        walletcomru: {
            name: "Credit/Debit Card / WCR",
            currencies: ["USD"],
        },
        payzon: {
            name: "Credit/Debit Card / PZ",
            currencies: ["USD", "EUR"],
        },
        bitexbit: {
            name: "Credit/Debit Card / BB",
            currencies: ["UAH"],
        },
        paystudio: {
            name: "Credit/Debit Card / PS",
            currencies: ["USD", "GBP", "EUR", "AUD", "NZD"],
        },
        stripe: {
            name: "Credit/Debit Card / St",
            currencies: ["USD", "EUR"],
        },
        settlepay_usd: {
            name: "Credit/Debit Card / SPU",
            currencies: ["USD"],
        },
        settlepay_eur: {
            name: "Credit/Debit Card / SPE",
            currencies: ["EUR"],
        },
        settlepay_kzt: {
            name: "Credit/Debit Card / SPK",
            currencies: ["KZT"],
        },
        garrypay: {
            name: "Credit/Debit Card / GP",
            currencies: ["USD", "EUR"],
        },
        texcent: {
            name: "Credit/Debit Card / TC",
            currencies: ["USD"],
        },
        billing_ru: {
            name: "Billing-Ru",
            currencies: ["RUB", "USD", "EUR", "UAH"],
        },
        billing_world: {
            name: "Billing-World",
            currencies: ["RUB", "USD", "EUR", "UAH"],
        },
        honeypay: {
            name: "Credit/Debit Card / HP",
            currencies: ["USD"],
        },
        square: {
            name: "Credit/Debit Card / SQ",
            currencies: ["USD"],
        },
        wellex: {
            name: "Credit/Debit Card / WX",
            currencies: ["RUB", "AZN", "KGS", "UZS"],
        },
        villpay: {
            name: "Credit/Debit Card / VP",
            currencies: ["RUB"],
        },
        payhub: {
            name: "Credit/Debit Card / PH",
            currencies: ["RUB"],
        },
        ppay: {
            name: "Credit/Debit Card / PA",
            currencies: ["USD", "RUB"],
        },
        pinikle: {
            name: "Credit/Debit Card / Pinikle",
            currencies: ["USD"],
        },
        enot: {
            name: "Credit/Debit Card / Enot",
            currencies: ["RUB", "USD", "EUR"],
        },
        decard: {
            name: "Credit/Debit Card / Decard",
            currencies: ["USD", "CLP", "PEN", "BRL"],
        },
        p2p_bank: {
            name: "Credit/Debit Card / P2P Bank",
            currencies: ["RUB", "KZT", "GEL"],
        },
        milkypay: {
            name: "Credit/Debit Card / Milkypay",
            currencies: ["RUB"],
        },
        todapay: {
            name: "Credit/Debit Card / Todapay",
            currencies: ["INR"],
        },
        payretailers: {
            name: "Credit/Debit Card / Payretailers",
            currencies: [
                "ARS",
                "BRL",
                "CLP",
                "COP",
                "CRC",
                "GTQ",
                "MXN",
                "PEN",
                "USD",
                "PAB",
            ],
        },
        dikeshield: {
            name: "Credit/Debit Card / DikeShield",
            currencies: ["USD"],
        },
        dikeshield_smartpay: {
            name: "Credit/Debit Card / DikeShield Smartpay",
            currencies: ["USD"],
        },
        alfakit: {
            name: "Credit/Debit Card / Alfakit",
            currencies: ["USD", "EUR"],
        },
        momentex: {
            name: "Credit/Debit Card / Momentex",
            currencies: ["INR"],
        },
        betspacemoney: {
            name: "Credit/Debit Card / Betspacemoney",
            currencies: ["EUR"],
        },
        betspacemoney_card: {
            name: "Credit/Debit Card / Betspacemoney Card",
            currencies: ["EUR", "USD"],
        },
        paycos: {
            name: "Credit/Debit Card / paycos",
            currencies: ["CLP", "MXN", "VES"],
        },
        storsunrise: {
            name: "Credit/Debit Card / storsunrise",
            currencies: ["USD"],
        },
        payepo: {
            name: "Credit/Debit Card / Payepo",
            currencies: ["USD"],
        },
        payepo_world: {
            name: "RemitEX",
            currencies: ["USD", "EUR", "GBP", "CAD"],
        },
        solidpayment: {
            name: "TopTechPayment",
            currencies: ["USD", "EUR", "GBP"],
        },
        transfertum: {
            name: "Transfertum",
            currencies: ["USD", "EUR"],
        },
        "cc-ext3": {
            name: "CEX24",
            currencies: ["EUR"],
            onlyButtons: [
                {
                    value: 300,
                    link: "https://buy.stripe.com/28oaEVaOcgwla40eWK",
                },
                {
                    value: 249,
                    link: "https://buy.stripe.com/7sI9AR3lK5RHb84eWL",
                },
                {
                    value: 220,
                    link: "https://buy.stripe.com/aEU28pbSgfsh0tqdSI",
                },
                {
                    value: 200,
                    link: "https://buy.stripe.com/4gw5kB1dCeodcc82a1",
                },
            ],
        },
    };
};
